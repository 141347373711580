.user-details-block .user-details-item {
    display: flex;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
  }
  
  .user-details-block .user-details-item .second-half {
    text-align: left;
    color: grey;
    font-size: 1.1em;
    font-weight: 600;
  }
  .user-details-block .user-details-item .first-half {
    width: 50%;
    color: grey;
    font-size: 1.1em;
    font-weight: 500;
  }
  .user-details-block .user-details-item .second-half {
    width: 50%;
    color: grey;
    font-size: 1.1em;
    font-weight: 600;
  }

  .makeStyles-content-24 {
    height: 100%!important;
}