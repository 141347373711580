.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropdown-toggle::after{
  color:#F1666A
}
.btn-primary{
  background-color: #F1666A;
  color: #fff;
}
.btn-check:active+.btn-primary.btn-check:checked+.btn-primary.btn-primary.active.btn-primary:active.show>.btn-primary.dropdown-toggle{
  background-color: #F1666A;
  color: #fff;
}

.carousel-indicators li {
  background-color: #02BACD;
  border-radius: 10px;
  height: 5px;
  width: 30px;
  border-radius: 50px;
  margin:0px 5px
}
.carousel-indicators .active {
  background-color: #F1666A;
  border-radius: 10px;
  height: 5px;
  width: 30px;
  border-radius: 50px;
}
.carousel-indicators {
  
}
.carousel-control-next, .carousel-control-prev{
  position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px ;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
}
/* table {
  border-collapse: separate !important;
  border-spacing: 0;
} */


input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  font-size: 20;
  opacity: 1; /* Firefox */
}

.navbar-expand-md .navbar-nav .dropdown-menu{
  background-color: #F1666A;
  color: #fff;
}
.dropdown-item {
  color: #fff;
}
.dropdown-menu{
  background-color: #F1666A;
}
.dropdown-item.active, .dropdown-item:active{
  background-color:#F1666A;
}

.dropdown-menu.show{
  background-color: #F1666A;
}


@media only screen and (max-width: 600px) {
  .kJXoHa{
    height: calc(100% -  56px) !important;
  }
}
#inputID::placeholder {
  color: #4c525c;
  opacity: 1;
}